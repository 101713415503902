.mainmenuitem
{
    padding-left: 9px;
    font-size: 24px;
    font-weight: bolder;
    border-radius: 0px;
    cursor: pointer;
}

.mainmenuitemselected
{
    padding-left: 9px;
    font-size: 24px;
    font-weight: bolder;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
}