.CloudCTILogo 
{
    left: 50px;
    height: 100px;
    width: 50%; 
    position: static;
    left: 1px;
    right: 500px;
    bottom: 125px;

}

.LanguageLogo
{
    height: 40px;
    width: 40px;
    left: 950px;
    right: 100px;
    top: 12px;
    bottom: 10px;
    position: relative;
}

.SwitchLanguages
{
    border: none;
    left: 1000px;
    right: 40px;
    top: -25px;
    bottom: 100px;
    position: relative;
}

.AccountLogo
{
    height: 30px;
    width:30px;
    left:1070px;
    position: relative;
    bottom: 50px;
}

.Username
{
    position: relative;
    left: 1100px;
    bottom:55px;   
}

.VerticalLine
{
    border-left: 1px solid black;
    height: 30px;
    position: relative;
    left: 1175px;
    bottom: 80px;
}

.SignButton
{
    position: relative;
    left: 1190px;
    bottom: 105px;
    padding: 0;
    border: none;
    background: none;
}

tr.pagetop1 
{
    height: 30px;
    background-color: #f6f8fc;
    display:table-row;    
}

tr.pagetop2 
{
    height: 139px;
    background-color: rgb(18, 168, 238);
}

.languageicon
{
    height: 24px; 
}

.accountlogo
{
    height: 26px;
    width: 26px;
}