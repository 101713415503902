
.borderbottom{
    border-bottom: 1px black solid;
}

.borderleft{
    border-left: 1px black solid;
}

.borderright{
    border-right: 1px black solid;
}

.bordertop{
    border-top: 1px black solid;
}

.pointer{
    cursor: pointer;
}

.tenantuseractivebutton{
    height: 15px;
    width: 45px;
}



