@media (max-width: 1200px)
{
    .width30{
        width: 20px;
        padding-left: 20px;
    }

    .textareaadduser
    {
        resize: none;
        height: 120px;
        width: 500px;
    }
    
    
}

@media (min-width : 1200px)
{
    .width30
    {
        width: 40px;
    }
    
    .textareaadduser
    {
        resize: none;
        height: 120px;
        width: 1000px;
    }
    
}