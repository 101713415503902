.slash{
    height:1px;
    width: 40px;
    background: rgb(82, 82, 255);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(-50deg);
    
    
}

.HomeLogo{
    padding-top: 0px;
    padding-bottom: 5px;
}

.slashImage{
    padding-bottom: -4px;
    padding-top: 4px;
}

.colomnintenanttable{
    border-top: 10px;
    border-left: 10px;
    border-bottom: 1px black solid;
    border-right: 1px black solid;
    color: black;
    border-top: 10000px;
}

.tanenttableheader{
    background-color:white;
    border: 1px black;
    border-top: 1px;
    border-bottom: 10px;
    
}

.borderbottomright{
    border-bottom: 1px black solid;
    border-right: 1px black solid;

}

.companyname{
    padding-left: 10px;
    width: 300px;
}

.borderleft{
    border-left: 1px black solid;
    height: 50px;
    width: 140px;
    padding-left: 10px;
}

.left-padding{
    padding-left: 10px;
}

.bold{
    font-weight: bold;
}

.nonebottomborder{
    border-bottom: none;
}

.tenantapisetting{
    border-bottom: none;
}

.borderbottomrightnonetop{
    border-bottom: 1px black solid;
    border-left: 1px black solid;
    border-right: none;
}

.tenantlicencetableheader{
    background-color: #71c1ec;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    width: 300px;
    height: 50px;
}

.tenantlicencetableheaderLicence{
    width: 10000px;
}

.tenantlicencetableheaderdescription{
    width: 900px;
}

.tenantlicencetableheaderQuantity{
    width: 5px;
}

.tenantusertable{
    background-color: #71c1ec;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    height: 50px;
}

.width50{
    width: 50px;
}

.tenantusertablecontent{
    padding-left: 10px;
}

.underlinetext{
    text-decoration: underline;
}

.right-padding{
    padding-right: 10px;
}

.editusertable{
    padding-top: 40px;
}

.tenantusertableedit{
    padding-bottom: -10px;
    padding-top: 5px;
    padding-right: -10px;
    
}

.enveloplogo{
    width: 30px;
    height: 30px;
    padding-top: 8px;
    
}

.tenantusertableinvite{
    padding-left: 5px;
    padding-top: 6px;
}

.tenantuseractivebutton{
    width: 40px;
    padding-left: 20px;
}

.underline{
    text-decoration: underline;
}

.width40{
    width: 80px;
}

.width80{
    width: 2000px;
}

.paddingleft{
    padding-left: 80px;
}

.noneborder{
    border: none;
}

.borderradius{
    
    border-radius: 15px;
}

.height50{
    height: 50px;
}

.trashwidth
{
    width: 20px;
}

.lightfontcolor
{
    color: darkgrey;
}

img.searchimage 
{
    width: 60px;
    height: 7   0px;
    padding-bottom: 6px;
}

.searchtenants
{
    height: 50px;
    width: 400px;
}

