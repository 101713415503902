.fontsizelarge{
 font-size: large;   
}

.horizontalline{
    border-top: 10px solid;
    width: 80px;

}

.blue{
    color: rgba(0, 179, 255, 0.952);
}

.usermainsettingtable{
    background-color: rgb(208, 230, 248);
    width: 1200px;
}

.fontsizesmall{
    font-size: small;
}

.red{
    color: red;
}

.backgroundwhite{
    background-color: aliceblue;
}

.saveusersettingsbutton{
    background-color: black;
    color: white;
    border-radius: 12px;
    width: 150px;
    height: 50px;
    cursor: pointer;
}

.dot{
    height: 6px;
    width: 6px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    
}

.paddingleft{
    padding-left: 5px;
}

.tablebackgroundcolor{
    background-color: rgb(208, 230, 248);
}

.paddingbottom{
    padding-bottom: 3.5px;
}

.inputtd{
    width: 1000px;
    height:40px;
    padding-left: 10px;
}

.inputapitd{
    width: 1000px;
    height:80px;
    padding-left: 20px;
    padding-top: 20px;   
}

@media (width < 1200px) {
    .textareaedituser
    {
        resize: none;
        width: 500px;
        height: 120px;
        padding-left: 10px;
    }
    
}

@media (width >= 1200px) {
     .textareaedituser
    {
        resize: none;
        width: 1000px;
        height: 120px;
        padding-left: 10px; 
    }
    
}