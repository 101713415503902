.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bluebar
{
    background-color: #0f9ddd;
}

.up6px 
{
  padding-bottom: 6px
}

.handpoint 
{
  cursor: pointer;
}

.darkgrey{
  color: darkgrey;
}

.greybackground{
  background-color: grey;
}

.greybackground{
  background-color: green
}

.black{
  color: black;
}

.passwordtoggleicon {
  cursor: pointer;
  font-size: 18px;
  margin-left: -60px;
}

@media (max-width : 1200px) {

  .freefooter
  {
    width: auto;
  }
}

@media only screen and (max-width : 375px)
 {
  .main
  {
    width: 375px;
  }

  .mainmenubar
  {
    width: 372px;
  }
  
  .mainmenuitem
  {
    padding-left: 9px;
    font-size: 12px;
    font-weight: bolder;
    border-radius: 0px;
    cursor: pointer;
  }

  .mainmenuitemselected
  {
    padding-left: 9px;
    font-size: 12px;
    font-weight: bolder;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
  }

  .usermainsettingtable
  {
    background-color: rgb(208, 230, 248);
    width: 370px;
  }
  .inputtd
  {
    width: 350px;
  }

  .textarea
  {
    height: 3cm;
    width: 350px;
    resize: none;
  }

  .footerresizeelement
  {
    font-size: 10px;
    margin-left: -10px;
    text-align: left;
  }

  .freefooter
  {
    width: 40px;
  }

  .marginrightresize
  {
    margin-right: 20px;
    text-align: left;
  }

  .searchtenants
  {
    height: 50px;
    width: 300px;
  }
  
  }

  @media (max-width : 1200px) 
  {
    .freeleftspace{
      width: 10px;
    }

    .width165
    {
      width: 165px;
    }

    .width1200
    {
      width: auto; 
      
    }

    .spacefromlefttable
    {
      width: 40px;
    }

    .inputtd
    {
      width: 500px;
      padding-left: 20px;
    }

    .freeleftspacetable
    {
      width: 50px;
    }
  }

  @media (min-width : 1200px) 
  {
    .width1200
    {
        width: 1200px;
    }

    .freeleftspace
    {
      width: 40px;
    }

    .width165
    {
      width: 165px;
    }
  }
  
.width100
{
  width: 100%;
}

.cloudctibackgroundtable
{
  background-color: rgb(208, 230, 248);
}

.cloudctibackgroundheadertable
{
  background-color: #71c1ec;
}

.bluebelowline{
  background-color: rgb(18, 168, 238);
  height: 100px;
  
}

@media  (min-width : 1200px) {

  .width1200
  {
      width: 1200px;
  }

  .freespace
  {
      width: 0px;
      background-color: white;
  }
}

@media  (max-width : 1200px) {

  .width1200
  {
      width: auto;
  }
  
  .freespace
  {
      width: 20px;
      background-color: white;
  }
  
}

.activetenants{
  padding-left: 0px;
  font-size: 30px;
  font-weight: bolder;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}

.bold
{
  font-weight: bold;
}

.pointer
{
  cursor: pointer;
}

.HomeLogo
{
  padding-top: 0px;
  padding-bottom: 5px;
}

.footer{
  left: 0;
  bottom: 0;
  width: 100%;
}

.usermainsettingtable{
  background-color: rgb(208, 230, 248);
  width: 1200px;
}

.fontsizelarge
{
  font-size: large;
}

.dot
{
  height: 6px;
  width: 6px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.fontsizesmall
{
  font-size: small;
}

.paddingleft
{
  padding-left: 5px;

}

.inputtd
{
  width: 1000px;
  height:40px;
  padding-left: 10px;
}

.passwordtoggleicon
{
  cursor: pointer;
  font-size: 18px;
  margin-left: -60px;
}

.green
{
  color: green;
}

.red
{
  color: red;
}

.saveusersettingsbutton
{
  background-color: black;
  color: white;
  border-radius: 12px;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.underline
{
  text-decoration: underline;
}

.borderleft
{
  border-left: 1px black;
}

.bordertop
{
  border-top: 1px black solid;
}

.borderright
{
  border-right: 1px black solid;
}

.width50
{
    width: 50px;
}

.blackbackground
{
  background-color: black;
}

.white
{
  color: white;
}

.borderbottom
{
  border-bottom: 1px black solid;
}

.fontsize30
{
  font-size: 30px;
}

.borderleft
{
  border-left: 1px black solid;
}